import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from './user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  $user: Observable<User>;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router
  ) {
    this.$user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return of(user);
        } else{
          return of(null);
        }
      })
    );
  }

  async signIn(email: string, password: string){
    const credential = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
    return credential.user;
  }

  async signUp(email: string, password: string){
    const credential = await this.afAuth.auth.createUserWithEmailAndPassword(email, password);
    return credential.user;
  }

  async signOut(){
    await this.afAuth.auth.signOut();
    return this.router.navigate(['/login']);
  }

  async resetPassword(email: string){
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }
}
