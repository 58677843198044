import { Component, OnInit } from '@angular/core';
import { ProductInfoService } from './../../services/product-info.service';
import { OrdersInfoService } from './../../services/orders-info.service'
import * as XLSX from 'xlsx';
import { promise } from 'protractor';
import { database } from 'firebase';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare var $: any;

@Component({
  selector: 'app-cargar-guias',
  templateUrl: './cargar-guias.component.html',
  styleUrls: ['./cargar-guias.component.scss']
})
export class CargarGuiasComponent implements OnInit {

  public originalFile: any;
  public fileData: any;
  public solicitudes: any;
  public eans: any;
  public statusFile: any;
  public fileIsOK: boolean;
  public fileIsUpload: boolean;
  public canUploadFile: boolean;
  public fileIsProcessing: boolean;
  public fileWasProcessed: boolean;
  public email: string;
  constructor(
    private productInfoService:ProductInfoService,
    private ordersInfoService:OrdersInfoService
  ) { 
    this.originalFile = '';
    this.fileData = [];
    this.eans = [];
    this.fileIsOK = false;
    this.fileWasProcessed = false;
    this.fileIsProcessing = false;
    this.fileIsUpload = false;
    this.canUploadFile = false;
    this.statusFile = [];
    this.solicitudes = [];
    this.email = '';
  }

  ngOnInit() {
    this.getSolicitudes();
  }

  public clearData(){
    this.originalFile = '';
    this.fileData = [];
    this.eans = [];
    this.fileIsOK = false;
    this.fileWasProcessed = false;
    this.fileIsProcessing = false;
    this.fileIsUpload = false;
    this.canUploadFile = false;
    this.statusFile = [];
    this.solicitudes = [];
    this.email = '';
  }

  public readFile(event){
    const file: File = event.target.files[0];
    this.originalFile = file;
    this.fileIsOK = true;
    this.fileData = [];
    this.statusFile = [];
    let reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e:any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const aoa = XLSX.utils.sheet_to_json(ws, {header: 1});
      aoa.shift();
      aoa.forEach( async (element:any[]) => {
        if(element.length == 0){
          console.log('Empty rows'); // Primer validación:  No es una fila vacia
          this.fileIsOK = false;
        }else if(element.length != 2 ){
          console.log('Los datos de la orden '+ element[0] +' están incompletos'); //2da validación: todas las columnas necesarias estan presentes.
          this.fileIsOK = false;
        }else{
          const orderIsValid = !isNaN(element[0]); // Verificar que sea un valor numérico
          const deliveryNumberIsValid = !isNaN(element[1]); // Verificar que sea un valor numérico
          if(!orderIsValid){ console.log('el dato ' + element[0] + ' es inválido'); this.fileIsOK = false; }
          if(!deliveryNumberIsValid){ console.log('el dato ' + element[1] + ' es inválido'); this.fileIsOK = false; }
          this.fileData.push(element);
          this.statusFile.push([
            orderIsValid,
            deliveryNumberIsValid
          ]);
          $.App.init();
        }
      });
      console.log(this.fileData);
    }
  };

  public subirArchivo(){
    const archivo: File = ($("#fileInput").prop('files').length > 0) ? $("#fileInput").prop('files')[0] : '';
    const correo =  $('#emailSolicitante').val();
    let formData:FormData = new FormData();
    formData.append('correo', correo);
    formData.append('archivo', archivo);
    formData.append('tipo_solicitud','guias');
    formData.append('nombre_archivo','trackings');
    $.ajaxSetup({
      beforeSend: function(xhr) {
        xhr.setRequestHeader('x-api-key', '7cc263a1-a490-4337-8302-151490495e56');
      }
    });
    $.ajax({
      method: "POST",
      url: "https://oldadmin.cuadra.com.mx/reportes/API/uploadFile/index.php",
      contentType:false,
      processData:false,
      dataType: "text",
      data: formData,
      headers: {
        'x-api-key':'7cc263a1-a490-4337-8302-151490495e56'
      },
      success: (response) => {
        console.log(JSON.parse(response));
        let res = JSON.parse(response);
        if(res.error){
          console.log(res.msg);
        }else{
          this.fileIsUpload = true;
        }
      },
      error: (response) => {
          console.log(response);
      }
    });	
  }

  public procesarArchivo(){
    this.fileIsProcessing = true;
    this.ordersInfoService.SaveTrackingNumbers().subscribe(
      response => {
        console.log(response);
        if(!response.error){
          $('#finish').fadeIn(500);
          $('#finish').fadeOut(500);
          this.clearData();
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  public emailIsSetted(){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.canUploadFile = re.test(this.email);
  }

  public getSolicitudes(){
    this.productInfoService.getSolicitudes('guias').subscribe(
      response => {
        console.log(response);
        this.solicitudes = response.msj;
      },
      error => {
        console.log(error);
      }
    )
  }

}
