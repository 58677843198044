import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from '../services/user';

declare var $ :any;

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, AfterViewInit {
  private user: User;
  public nombre: string;
  public email: string;
  public foto: string;

  constructor(private auth: AuthService, private afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.user = this.afAuth.auth.currentUser;
    this.nombre = '-';
    this.email = '-';
    this.foto = '-';//TODO: Asignar este dato en el formulario de registro
  }

  ngAfterViewInit(): void {
    $.App.init();
  }

  public signOut(){
    this.auth.signOut();
  }
}
