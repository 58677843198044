import { Component, OnInit, AfterViewInit } from '@angular/core';
import { OrdersInfoService } from './../services/orders-info.service';
import { AuthService } from './../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { first, tap } from 'rxjs/operators';
declare var $ :any;

@Component({
  selector: 'app-leftside-menu',
  templateUrl: './leftside-menu.component.html',
  styleUrls: ['./leftside-menu.component.scss']
})
export class LeftsideMenuComponent implements OnInit, AfterViewInit {
  constructor(
    private ordersInfoService:OrdersInfoService,
    private afAuth: AngularFireAuth,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $.App.init();
  }

  public getPackingList(){
    this.ordersInfoService.getPackingList().subscribe(
      response => {
        console.log(response);
      },
      error => {
        console.log(error);
      }
    );
  }

}
