import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
// Services
import { ProductInfoService } from './../../services/product-info.service';
import { OrdersInfoService } from './../../services/orders-info.service'
import { IfStmt, ThrowStmt } from '@angular/compiler';
declare var $: any;

@Component({
  selector: 'app-administrar-ofertas',
  templateUrl: './administrar-ofertas.component.html',
  styleUrls: ['./administrar-ofertas.component.scss']
})
/**
 * 1. Leer documento LISTO
 * 2. Verificar que la información sea válida LISTO
 *   2.1 Modelos/EAN existan LISTO
 *   2.2 Precio y descuento en formato correcto LISTO
 *   2.2 Fechas en formato correcto LISTO
 * 3. Administrar ofertas LISTO
 *   3.1  Si el modelo tiene descuento, se agrega a ofertas LISTO
 *   3.2  si no es así,sale de ofertas LISTO
 * 4. Reindexar (?)
**/
export class AdministrarOfertasComponent implements OnInit {
  public fileData          :any;     // información del CSV
  public InfoDataIsValid   :any;     // información sobre el estatus de la información (es válida o no)
  public originalFile      :any;     // archivo original
  public eans              :any;     // relación de EAN registrados en Magento
  public comparison        :any;     // respuesta del procesamiento de datos en Magento  
  public email             :string;  // email del usuario
  public message           :string;  // mensaje a mostrar al usuario
  public dataIsOk          :boolean; // sí la información es correcta, se solicita correo electrónico
  public emailIsOk         :boolean; // si el correo es válido, se registra la solicitud
  public isUploading       :boolean; // si es positivo, significa que se está subiendo el archivo
  public canUploadFile     :boolean; // Si es verdadero, indica que es posible realizar la carga del documento
  public Uploaded          :boolean; // si es positivo, significa que ya esta arriba
  public isReading         :boolean;
  constructor(
    private productInfoService :ProductInfoService,
    private ordersInfoService  : OrdersInfoService
  ) { 
    this.fileData         = [];
    this.InfoDataIsValid  = [];
    this.eans             = [];
    this.comparison       = [];
    this.email            = '';
    this.dataIsOk         = false;
    this.emailIsOk        = false;
    this.isUploading      = false;
    this.Uploaded         = false;
    this.canUploadFile    = false;
    this.isReading        = false;
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.getEans();
  }
  
  private clearData(){
    console.log('%c Cleaning data... ','background:#0acf97; color: white')
    this.fileData         = [];
    this.InfoDataIsValid  = [];
    this.comparison       = [];
    this.email            = '';
    this.dataIsOk         = false;
    this.emailIsOk        = false;
    this.isUploading      = false;
    this.Uploaded         = false;
    this.canUploadFile    = false;
    this.isReading        = false;
    $('#fileInput').val('');
  }

  /**
   * get All registred EAN
   */
  private getEans(){
    this.eans = [];
    this.productInfoService.getSimples().subscribe(
      response => { this.eans = response.eans; },
      error    => { console.log(error); }
    )
  }


  /**
   * Tuplas: EAN | Modelo (?) | Precio normal | precio especial (puede ir vacio, pero no ser cero) | Fecha inicio | Fecha Fin | ¿es ultimas tallas?
  **/
  public readFile(event){
    const file: File = event.target.files[0];
    this.originalFile = file;
    let reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    this.clearData();
    this.dataIsOk = true;
    this.isReading = true;
    reader.onload = (e:any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const aoa = XLSX.utils.sheet_to_json(ws, {header: 1});
      aoa.shift();
      aoa.forEach( async (element:any[]) => {
        if(element.length == 0){
          console.log('Empty rows'); // Primer validación:  No es una fila vacia
          this.dataIsOk = false;
          this.message = 'El archivo tiene filas vacias, favor de validarlo e intentar nuevamente';
          console.log('%c ' + this.message + ' ','background: #c83000; color: white');
          $.NotificationApp.send("Error en el archivo","El documento tiene celdas vacias","bottom-right","#946f06","warning");
        } else {
          const ean_exist = this.eans.find((e) => { return e.sku == element[0] }); // Verificar que el EAN este registrado en Magento
          const modelo_is_empty = this.isEmpty(element[1])
          const price_is_ok = !this.priceFormat(element[3],'price') && !this.isEmpty(element[3]) ? true : false;
          const discount_is_ok = !this.priceFormat(element[5],'discount') || this.isEmpty(element[5]) ? true : false;
          let special_from_date_is_ok = true;
          let special_to_date_is_ok = true;
          let date_start, date_end;
          if(element[7] == '' || this.isEmpty(element[7])){
            special_from_date_is_ok =  true;
          }else{
            date_start = this.ExcelDateToJSDate(element[7]);
            special_from_date_is_ok = this.isValidDate(date_start);
          }
          if(element[8] == '' || this.isEmpty(element[8])){
            special_to_date_is_ok =  true;
          }else{
            date_end = this.ExcelDateToJSDate(element[8]);
            special_to_date_is_ok = this.isValidDate(date_end);
          }
          // console.log(element,[
          //   !!ean_exist,
          //   !modelo_is_empty,
          //   price_is_ok,
          //   discount_is_ok,
          //   special_from_date_is_ok,
          //   special_to_date_is_ok,
          //   this.dataIsOk
          // ]);
          if(!!ean_exist && !modelo_is_empty && price_is_ok && discount_is_ok && special_from_date_is_ok && special_to_date_is_ok && this.dataIsOk){
            this.dataIsOk = true;
            $.NotificationApp.send("¡Muy Bien!","El documento se ve bien ;). Ingresa tu correo para continuar ","bottom-right","#15925f","success");
          } else {
            this.dataIsOk = false;
            $.NotificationApp.send("Error en el archivo","El documento presenta varios errores, favor de solicionarlos e intentar nuevamente","bottom-right","#946f06","warning");
          }
          /* Storage data to upload */
          this.fileData.push([
            element[0],
            element[1],
            element[3],
            element[5],
            date_start == undefined ? '' : date_start,
            date_end == undefined ? '' : date_end
          ]);          
          /* Storage status data  */
          this.InfoDataIsValid.push([
            !!ean_exist,
            !modelo_is_empty,
            price_is_ok,
            discount_is_ok,
            special_from_date_is_ok,
            special_to_date_is_ok
          ]);
        }
      });
      this.isReading = false;
      $(document).ready(function(){
        $.App.init();
      });
    }
  };

  /**
   * 
   */
  public cargarInformacion(){
    this.isUploading = true;
    $.NotificationApp.send("Cargando...","Estamos subiendo la información, espera un poco","bottom-right","#000000","info");
    this.ordersInfoService.uploadPromotionsInfo(this.fileData, this.email).subscribe(
      response => {
        if(!response.error){
          //console.log(response);
          this.comparison = response.info;
          $.NotificationApp.send("Generando respaldos","Esto tomara solo un minuto, sigue con nosotros","bottom-right","#15925f","success");
          this.backupFile();
        }else{
          console.warn(response);
          $.NotificationApp.send("Error","Algo salio mal. Favor de intentarlo nuevamente","bottom-right","#FF0000","error");
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error","Algo salio mal. Favor de intentarlo nuevamente","bottom-right","#FF0000","error");
        this.Uploaded = false;
        this.isUploading = false;
      }
    );
  }

  private backupFile(){
    const archivo = this.originalFile;
    const correo =  this.email;
    let formData:FormData = new FormData();
    formData.append('correo', correo);
    formData.append('archivo', archivo);
    formData.append('tipo_solicitud','descuentos');
    formData.append('nombre_archivo','solicitud-descuentos');
    $.ajaxSetup({
      beforeSend: function(xhr) {
        xhr.setRequestHeader('x-api-key', '7cc263a1-a490-4337-8302-151490495e56');
      }
    });
    $.ajax({
      method: "POST",
      url: "https://oldadmin.cuadra.com.mx/reportes/API/uploadFile/index-manager.php",
      contentType:false,
      processData:false,
      dataType: "text",
      data: formData,
      headers: {
        'x-api-key':'7cc263a1-a490-4337-8302-151490495e56'
      },
      success: (response) => {
        let res = JSON.parse(response);
        if(res.error){
          console.error(res.msg);
          $.NotificationApp.send("Error","Ocurrio un error durante el respaldo, pero la información fue cargada.","bottom-right","#FF0000","error");
        }else{
          setTimeout(() => {
            $.NotificationApp.send("Proceso finalizado","Se ha registrado la información en Magento","bottom-right","#15925f","success");
          }, 1500);
          this.Uploaded = true;
          console.log(this.comparison);
        }
      },
      error: (response) => {
          console.warn(response);
          $.NotificationApp.send("Error","Algo salio mal","bottom-right","#FF0000","error");
      }
    });	
  }

  /**
   * Verifies if the price has the right format.
   * actual verifications:
   * 1. Is not an empty string
   * 2. Doesn´t have money format
   * 3. Is bigger than zero
   * @param price value to evaluate
   */
  private priceFormat(price:any, type:string){
    if(isNaN(price)) return 'isNaN';
    if(price <= 10) return 'less than zero';
    return false;
  }

  /**
   * Repairs strange convertion of date into Date Object
   * @param serial Date to format
   */
  private ExcelDateToJSDate(serial) {
    var utc_days  = Math.floor(serial - 25567); // Reduces the equivalent to 70 days and remove decimals
    var utc_value = utc_days * 86400; //convert days into seconds                                     
    var date_info = new Date(utc_value * 1000); 
    return date_info.getFullYear()+'-'+("0" + (date_info.getMonth() + 1)).slice(-2)+'-'+("0" + date_info.getDate()).slice(-2); // YYYY-MM-DD
  }

  /**
   * Evalue an string as a date in YYYY/MM/DD format
   * @param dateString 
   * Regex => 
   * for match beginning: ^
   * for year (YYYY): (\d{4}) => digits{four times}
   * for month (MM): (\d{2}) => digits{two times}
   * for day (DD): (\d{2}) => digits{two times}
   * for slash or dash between numbers: [-\/]
   * for match end: $
   */
  private isValidDate(dateString) {
    var regEx = /^(\d{4})[-\/](\d{2})[-\/](\d{2})$/;
    if(!dateString.match(regEx)) return false;  // Invalid format
    // if format is right, verifies if the date is valid
    var d = new Date(dateString);
    var dNum = d.getTime();
    if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0,10) === dateString;
  }

  /**
   * Checks if obj is an empty object. It is return true
   */
  private isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key) || !isNaN(obj))
            return false;
    }
    if(!isNaN(obj)){
      return false
    }
    return true;
  }

  /**
   * Regex for email sintax
   * Every email need to has:
   *  >user
   *  >@ 
   *  >domaing with at least one dot
   */
  public emailIsSetted(){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.canUploadFile = re.test(this.email);
  }
}


