import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from './../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
declare var $ :any;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
	public form: FormGroup;

	constructor(private auth: AuthService, private afAuth: AngularFireAuth, private router: Router) {
		this.form = new FormGroup({
			email: new FormControl('', [Validators.required, Validators.email]),
			password: new FormControl('', [Validators.required])
		});
	}

	ngOnInit() {
		this.afAuth.auth.signOut();
	}

	ngAfterViewInit(): void {
		document.body.classList.add("authentication-bg");
		$.App.init();
	}

	ngOnDestroy(): void {
		document.body.classList.remove("authentication-bg");
	}

	public submit(){
		if(this.form.valid){
			this.auth.signIn(this.form.value.email, this.form.value.password).then(
				user => {
					this.router.navigate(['/']);
				},
				reason => {
					$.NotificationApp.send(reason.code, reason.message, 'bottom-right', '#fa5c7c', 'error', 5000);
			});
		}
	}
}
