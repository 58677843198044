import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { Error404Component } from './error404/error404.component';
import { ConfirmacionMailComponent } from './confirmacion-mail/confirmacion-mail.component';
import { NuevosModelosComponent } from './pages/nuevos-modelos/nuevos-modelos.component';
import { CargarGuiasComponent } from './pages/cargar-guias/cargar-guias.component';
import { ActualizarGaleriaComponent } from './pages/actualizar-galeria/actualizar-galeria.component';
import { DhlComponent } from './pages/dhl/dhl.component';
import { AdministrarOfertasComponent } from './pages/administrar-ofertas/administrar-ofertas.component';
import { AdministrarNovedadesComponent } from './pages/administrar-novedades/administrar-novedades.component';
import { AbandonedCartsComponent } from './abandoned-carts/abandoned-carts.component';
import { AdministrarComplementosComponent } from './pages/administrar-complementos/administrar-complementos.component';
import { AdministrarUpsellComponent } from './pages/administrar-upsell/administrar-upsell.component';
import { ActualizarEansComponent } from './pages/actualizar-eans/actualizar-eans.component';
import { OrdenesEstatusComponent } from './ordenes-estatus/ordenes-estatus.component';
import { CategoriasComponent } from './categorias/categorias.component';

const redirectUnauthorizedToAdminLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    pathMatch: 'full'
  },
  {
    path: 'nuevos-modelos',
    component: NuevosModelosComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: 'administrar-categorias',
    component: CategoriasComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: 'confirmacion-mail/:emailEncoded',
    component: ConfirmacionMailComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: 'actualizar-galeria',
    component: ActualizarGaleriaComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: 'cargas-guias',
    component: CargarGuiasComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: 'ofertas',
    component: AdministrarOfertasComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  { 
    path: 'dhl',
    component: DhlComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: 'novedades',
    component: AdministrarNovedadesComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: 'complementos',
    component: AdministrarComplementosComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: 'upsell',
    component: AdministrarUpsellComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: 'eans',
    component: ActualizarEansComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: 'carritos-abandonados',
    component: AbandonedCartsComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: 'ordenes-estatus',
    component: OrdenesEstatusComponent,
    pathMatch: 'full',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToAdminLogin } 
  },
  {
    path: '**',
    component: Error404Component,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
