import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
// Services
import { ProductInfoService } from './../../services/product-info.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare var $: any;

@Component({
  selector: 'app-actualizar-galeria',
  templateUrl: './actualizar-galeria.component.html',
  styleUrls: ['./actualizar-galeria.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('1s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ActualizarGaleriaComponent implements OnInit {
  public sku            :string;
  public galeria        :any;
  public configurables  :any;
  public skuHasImages   :boolean;
  public skuIsUpdating  :boolean;
  public update         :boolean;
  constructor(
    private productInfoService:ProductInfoService
  ) { 
    this.sku            = '';
    this.skuHasImages   = false;
    this.update         = false;
    this.skuIsUpdating  = false;
    this.galeria        = [];
  }

  ngOnInit() {}

  public clearData(){
    this.sku            = '';
    this.skuHasImages   = false;
    this.update         = false;
    this.skuIsUpdating  = false;
    this.galeria        = [];
  }


  /**
   * Verifica si el modelo cuenta con al menos una imágen
   */
  public evaluarModelo(){
    if(this.sku == ''){
      $.NotificationApp.send("ATENCIÓN","NO has capturado ningun modelo.","bottom-right","#15925f","warning");
    }else{
      this.galeria = [];
      this.productInfoService.verifyGallery(this.sku).subscribe(
        response => {
          console.log(response);
          if(response.error){
            if(response.imagenes == 0){
              $.NotificationApp.send("ATENCIÓN","No se encontró ninguna imagen correspondiente al modelo","bottom-right","#15925f","warning");
            } else {
              $.NotificationApp.send("ATENCIÓN",response.mensaje,"bottom-right","#15925f","warning");
            }
          }else{
            $.NotificationApp.send("¡Muy Bien!",response.mensaje,"bottom-right","#15925f","success");
            let modelo_color = this.sku.split('_');
            this.skuHasImages = true;
            for(let i = 0; i < response.imagenes ; i++){
              this.galeria.push(
                'http://209.126.191.45/Amazon/Magento/'+modelo_color[0].toUpperCase()+'-'+modelo_color[1].toUpperCase()+'-0'+(i+1)+'.jpg'
              );
            }
            console.log(this.galeria);
          }
        },
        error => {
          console.log(error)
        }
      );
    }
  }

  public actualizarGaleria(){
    this.skuIsUpdating = true;
    this.update = false;
    this.productInfoService.updateGallery(this.sku, this.galeria).subscribe(
      response => {
        console.log(response);
        if(!response.error){
          $.NotificationApp.send("Proceso finalizado",response.mensaje,"bottom-right","#15925f","success");
          this.clearData();
        } else {
          $.NotificationApp.send("Algo no salio bien","Favor de intentarlo de nuevo","bottom-right","#15925f","warning");
          this.clearData();
        }
      },
      error => {
        $.NotificationApp.send("Error","Algo salio mal. Por favor, intentalo de nuevo.","bottom-right","#946f06","error");
        this.clearData();
        console.log(error);
      }
    );
  }

}
