import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { App } from './app'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class OrdersInfoService extends App {

  constructor(private http:HttpClient) {
    super();
  }

  public getOrdersByStatus():Observable<any>{
    const params:string = JSON.stringify({
      action: 'getOrders'
    });
    return this.http.post(this.url+`orders/byStatus/`, params, { headers: this.headers });
  }

  public getPackingList():Observable<any>{
    const params:string = JSON.stringify({
      action: 'getPackingList'
    });
    return this.http.post('https://oldadmin.cuadra.com.mx/procesos/correo_preparando.php', params, { headers: this.headers });
  }

  public SaveTrackingNumbers():Observable<any>{
    return this.http.get('https://oldadmin.cuadra.com.mx/procesos/update_tracking_list.php', {});
  }

  public SaveTrackingNumbersDHL():Observable<any>{
    return this.http.get('https://oldadmin.cuadra.com.mx/reportes/API/tracking-number/dhl.php', {});
  }

  public advancedDHL(ordenes):Observable<any>{
    const params:string = JSON.stringify({
      ordenes
    });
    return this.http.post(this.url+'tracking-number/', params, { headers: this.headers });
  }

  public setTrackingNumbers(ordenes):Observable<any>{
    const params:string = JSON.stringify({
      ordenes
    });
    return this.http.post(this.url+'tracking-number/setTrackingNumber.php', params, { headers: this.headers });
  }

  public status(orden):Observable<any>{
    const params:string = JSON.stringify({
      orden
    });
    return this.http.post(this.url+'status/index.php', params, { headers: this.headers });
  }

  public updateStatus(orden,state,status):Observable<any>{
    const params:string = JSON.stringify({
      orden,
      status,
      state
    });
    return this.http.post(this.url+'status/updateOrder.php', params, { headers: this.headers });
  }

  public uploadPromotionsInfo(fileData:any[], email:string):Observable<any>{
    const params:string = JSON.stringify({
      descuentos: fileData,
      accion    : 'descuentos',
      email     : email
    });
    return this.http.post(this.url+`promotions/`, params, {headers: this.headers});
  }

}
