import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
// Services
import { ProductInfoService } from './../../services/product-info.service';
import { OrdersInfoService } from './../../services/orders-info.service'
import { IfStmt, ThrowStmt } from '@angular/compiler';
import { UpperCasePipe } from '@angular/common';
declare var $: any;


@Component({
  selector: 'app-administrar-upsell',
  templateUrl: './administrar-upsell.component.html',
  styleUrls: ['./administrar-upsell.component.scss'],
  providers: [ UpperCasePipe ]
})
export class AdministrarUpsellComponent implements OnInit {
  public fileData          :any;     // información del CSV
  public InfoDataIsValid   :any;     // información sobre el estatus de la información (es válida o no)
  public originalFile      :any;     // archivo original
  public configurables     :any;     // relación de EAN registrados en Magento
  public comparison        :any;     // respuesta del procesamiento de datos en Magento  
  public email             :string;  // email del usuario
  public message           :string;  // mensaje a mostrar al usuario
  public dataIsOk          :boolean; // sí la información es correcta, se solicita correo electrónico
  public emailIsOk         :boolean; // si el correo es válido, se registra la solicitud
  public isUploading       :boolean; // si es positivo, significa que se está subiendo el archivo
  public canUploadFile     :boolean; // Si es verdadero, indica que es posible realizar la carga del documento
  public Uploaded          :boolean; // si es positivo, significa que ya esta arriba
  public isReading         :boolean;
  public canStart          :boolean;
  constructor(
    private productInfoService : ProductInfoService,
    private ordersInfoService  : OrdersInfoService,
    private _UpperCasePipe     : UpperCasePipe
  ) { 
    this.fileData         = [];
    this.InfoDataIsValid  = [];
    this.configurables    = [];
    this.comparison       = [];
    this.email            = '';
    this.dataIsOk         = false;
    this.emailIsOk        = false;
    this.canStart         = false;
    this.isUploading      = false;
    this.Uploaded         = false;
    this.canUploadFile    = false;
    this.isReading        = false;
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.getConfigurables();
  }
  
  private clearData(){
    console.log('%c Cleaning data... ','background:#0acf97; color: white')
    this.fileData         = [];
    this.InfoDataIsValid  = [];
    this.comparison       = [];
    this.email            = '';
    this.dataIsOk         = false;
    this.emailIsOk        = false;
    this.isUploading      = false;
    this.Uploaded         = false;
    this.canUploadFile    = false;
    this.isReading        = false;
    $('#fileInput').val('');
  }

  private getConfigurables(){
    this.productInfoService.getProducts('configurable').subscribe(
      response => {
        if(response.error){
          $.NotificationApp.send("Algo salio mal","No pudimos obtener la información de los modelos existentes en la tienda :(","bottom-right","#000000","danger");
        }else{
          this.configurables = response.products;
          this.canStart = true;
          $.NotificationApp.send("Ya podemos comenzar","Ya puedes cargar la información de los complementos :)","bottom-right","#000000","success");
        }
      },
      error => {
        $.NotificationApp.send("Algo salio mal","No pudimos obtener la información de los modelos existentes en la tienda. Error interno :(","bottom-right","#000000","danger");
        console.error(error)
      }
    )
  }

  /**
   * Tuplas: Modelo | Complementos
  **/
  public readFile(event){
    const file: File = event.target.files[0];
    this.originalFile = file;
    let reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    this.clearData();
    this.dataIsOk = true;
    this.isReading = true;
    reader.onload = (e:any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const aoa = XLSX.utils.sheet_to_json(ws, {header: 1});
      aoa.shift();
      aoa.forEach( async (element:any[]) => {
        if(element.length == 0){
          console.log('Empty rows'); // Primer validación:  No es una fila vacia
          this.dataIsOk = false;
          $.NotificationApp.send("Error en el archivo","El documento tiene celdas vacias","bottom-right","#000000","error");
        } else {
          const configurableExist = this.configurables.find((e) => { 
            return e.sku == this._UpperCasePipe.transform(element[0].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) 
          });
          let complementos_temp = element[1].split(',');
          let anyoneDoesntExist = false, AllComplementsExist = true;
          let complementos = [];
          complementos_temp.forEach( (element:string) => {
            anyoneDoesntExist = this.configurables.find((e) => { 
              return e.sku == this._UpperCasePipe.transform(element.trim()) 
            });
            complementos.push(this._UpperCasePipe.transform(element.trim()) )
            if(anyoneDoesntExist == undefined){
              AllComplementsExist = false;
            }
          });
          /* If Any of the models doesn´t exists, throw an error and doens */
          if( configurableExist == undefined || !AllComplementsExist){
            this.dataIsOk = false;
            $.NotificationApp.send("Error en el archivo","El documento presenta varios errores, favor de solicionarlos e intentar nuevamente","bottom-right","#000000","error");
          }
          /* Storage data to upload */
          this.fileData.push([
            this._UpperCasePipe.transform(element[0].normalize("NFD").replace(/[\u0300-\u036f]/g, "")),
            complementos,
          ]);          
          /* Storage status data  */
          this.InfoDataIsValid.push([
            !!configurableExist,
            AllComplementsExist
          ]);
        }
      });
      this.isReading = false;
      $(document).ready(function(){
        $.App.init();
      });
      console.log(this.fileData, this.InfoDataIsValid);
    }
  };

  /**
   * 
   */
  public cargarInformacion(){
    this.isUploading = true;
    $.NotificationApp.send("Cargando...","Estamos subiendo la información, espera un poco","bottom-right","#000000","info");
    this.productInfoService.setUpsellProducts(this.fileData).subscribe(
      response => {
        if(!response.error){
          console.log(response)
          $.NotificationApp.send("Generando respaldos","Esto tomara solo un minuto, sigue con nosotros","bottom-right","#15925f","success");
          this.backupFile();
        }else{
          console.warn(response);
          $.NotificationApp.send("Error","Algo salio mal. Favor de intentarlo nuevamente","bottom-right","#FF0000","error");
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error","Algo salio mal. Favor de intentarlo nuevamente","bottom-right","#FF0000","error");
        this.Uploaded = false;
        this.isUploading = false;
      }
    );
  }

  private backupFile(){
    const archivo = this.originalFile;
    const correo =  this.email;
    let formData:FormData = new FormData();
    formData.append('correo', correo);
    formData.append('archivo', archivo);
    formData.append('tipo_solicitud','complementos');
    formData.append('nombre_archivo','complementos');
    $.ajaxSetup({
      beforeSend: function(xhr) {
        xhr.setRequestHeader('x-api-key', '7cc263a1-a490-4337-8302-151490495e56');
      }
    });
    $.ajax({
      method: "POST",
      url: "https://oldadmin.cuadra.com.mx/reportes/API/uploadFile/index-manager.php",
      contentType:false,
      processData:false,
      dataType: "text",
      data: formData,
      headers: {
        'x-api-key':'7cc263a1-a490-4337-8302-151490495e56'
      },
      success: (response) => {
        let res = JSON.parse(response);
        if(res.error){
          console.error(res.msg);
          $.NotificationApp.send("Error","Ocurrio un error durante el respaldo, pero la información fue cargada.","bottom-right","#FF0000","error");
        }else{
          setTimeout(() => {
            $.NotificationApp.send("Proceso finalizado","Se ha registrado la información en Magento","bottom-right","#15925f","success");
          }, 1500);
          this.Uploaded = true;
          console.log(this.comparison);
        }
      },
      error: (response) => {
          console.warn(response);
          $.NotificationApp.send("Error","Algo salio mal","bottom-right","#FF0000","error");
      }
    });	
  }

  /**
   * Checks if obj is an empty object. It is return true
   */
  private isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key) || !isNaN(obj))
            return false;
    }
    if(!isNaN(obj)){
      return false
    }
    return true;
  }

  /**
   * Regex for email sintax
   * Every email need to has:
   *  >user
   *  >@ 
   *  >domaing with at least one dot
   */
  public emailIsSetted(){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.canUploadFile = re.test(this.email);
  }
}


