import { Component, OnInit, ɵConsole } from '@angular/core';
import * as XLSX from 'xlsx';
// Services
import { OrdersInfoService } from './../../services/orders-info.service';
declare var $: any;

@Component({
  selector: 'app-dhl',
  templateUrl: './dhl.component.html',
  styleUrls: ['./dhl.component.scss']
})
export class DhlComponent implements OnInit {
  public fileData:        any; // información del CSV
  public InfoDataIsValid: any; // información sobre el estatus de la información (es válida o no)
  public originalFile:    any; // archivo original
  public temporalData:    any;
  public filteredData:    any[];
  public processedData:   any[];
  public email:string; // email del usuario
  public message:string; // mensaje a mostrar al usuario
  public dataIsOk:boolean; // sí la información es correcta, se solicita correo electrónico
  public emailIsOk:boolean; // si el correo es válido, se registra la solicitud
  public isUploading:boolean; // si es positivo, significa que se está subiendo el archivo
  public Uploaded:boolean; // si es positivo, significa que ya esta arriba
  public processing:      boolean;//
  public processed:boolean;
  public isReading:       boolean; //si esta validando la información del documento
  constructor(
    private ordersInfoService:OrdersInfoService
  ) { 
    this.fileData         = [];
    this.InfoDataIsValid  = [];
    this.filteredData     = [];
    this.processedData    = [];
    this.temporalData     = [];
    this.email = '';
    this.message = '';
    this.dataIsOk = false;
    this.emailIsOk = false;
    this.isUploading = false;
    this.Uploaded = false;
    this.processing = false;
    this.processed = false; 
    this.isReading        = false;
  }

  ngOnInit() {
  }

  public clearData(){
    console.log('%c Cleaning data... ','background:#0acf97; color: white')
    this.fileData         = [];
    this.InfoDataIsValid  = [];
    this.temporalData     = [];
    this.filteredData     = [];
    this.processedData    = [];
    this.email            = '';
    this.message          = '';
    this.dataIsOk         = false;
    this.emailIsOk        = false;
    this.isUploading      = false;
    this.Uploaded         = false;
    this.processing       = false;
    this.processed        = false;
    this.isReading        = false;
    $('#fileInput').val('');
  }

  public readFile(event){
    console.log('%c Reading File... ','background:#2196f3; color: white');
    $.NotificationApp.send("Validando información","Estamos verificando la información del documento","bottom-right","#003861","info");
    const file: File = event.target.files[0];
    this.originalFile = file;
    this.dataIsOk = true;
    this.isReading = true;
    this.fileData = [];
    let reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e:any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const aoa = XLSX.utils.sheet_to_json(ws, {header: 1});
      aoa.shift();
      aoa.forEach( async (element:any[]) => {
        this.temporalData.push({
          'order':element[0],
          'tracking':element[1]
        });
      });
      var dataArr = this.temporalData.map(item=>{
        return [item.order,item]
      }); // creates array of array
      var maparr = new Map(dataArr); // create key value pair from array of array
      this.fileData = [...maparr.values()]; //converting back to array from mapobject
      this.validateOrders();      
    }
  }

  public validateOrders(){
    this.ordersInfoService.advancedDHL(this.fileData).subscribe(
      response => {
        console.log(response);
        response.data.forEach(element => {
          if(element.state == 'processing'){
            this.filteredData.push(element);
          }
        });
        if(this.filteredData.length == 0){
          $.NotificationApp.send("ATENCIÓN!","Ninguna de las ordenes del archivo puede ser procesada","bottom-right","#003861","warning");
          this.clearData();
        } else {
          this.processedData = response.data;
          this.isReading = false;
          $.NotificationApp.send("¡Listo!","A continuación te mostramos el análisis del archivo","bottom-right","#003861","info");
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR","Algo salio mal. Intentalo de nuevo","bottom-right","#000000","error");
      }
    );
  }

  public setTrackingNumber(){
    $.NotificationApp.send("Procesando...","Estamos cargando la información a Magento","bottom-right","#003861","info");
    this.processing = true;
    this.ordersInfoService.setTrackingNumbers(this.filteredData).subscribe(
      response => {
        this.processing = false;
        this.processed = true;
        $.NotificationApp.send("Finalizado","Cargamos la información sin ningun problema","bottom-right","#089200","success");
        console.log(response);
      },
      error => {
        this.clearData();
        console.warn(error);
        $.NotificationApp.send("ERROR","Algo Salio Mal, favor de intentarlo nuevamente","bottom-right","#003861","error");
      }
    )
  }

  public loadNWork(){
    this.isUploading = true;
    const correo =  $('#emailSolicitante').val();
    let formData:FormData = new FormData();
    formData.append('correo', 'test@testing');
    formData.append('archivo', this.originalFile);
    formData.append('tipo_solicitud','guiasdhl');
    formData.append('nombre_archivo','trackingsdhl');
    $.ajaxSetup({
      beforeSend: function(xhr) {
        xhr.setRequestHeader('x-api-key', '7cc263a1-a490-4337-8302-151490495e56');
      }
    });
    $.ajax({
      method: "POST",
      url: "https://oldadmin.cuadra.com.mx/reportes/API/uploadFile/index-manager.php",
      contentType:false,
      processData:false,
      dataType: "text",
      data: formData,
      headers: {
        'x-api-key':'7cc263a1-a490-4337-8302-151490495e56'
      },
      success: (response) => {
        console.log(JSON.parse(response));
        let res = JSON.parse(response);
        if(res.error){
          console.log(res.msg);
        }else{
          console.log(response);
          this.Uploaded = true;
        }
      },
      error: (response) => {
          console.log(response);
      }
    });
  }

  public processInformation(){          
    this.processing = true;
    this.ordersInfoService.SaveTrackingNumbersDHL().subscribe(
      response => {
        console.log(response);
        if(!response.error){
          console.log('%c ' + response.msg + ' ','background:#4caf50; color: white')
          this.processed = true;
          $('#finish').fadeIn(500).delay(1000).fadeOut(500);
        } else {
          console.log('%c ' + response.msg + ' ','background:#ff2222; color: white')
          $('#fatal-error').fadeIn(500).delay(1000).fadeOut(500);
        }
      },
      error => {
        console.log(error);
        console.log('%c ' + error + ' ','background:#ff2222; color: white')
        $('#fatal-error').fadeIn(500).delay(1000).fadeOut(500);
      }
    );
  }
}
