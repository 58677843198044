import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-abandoned-carts',
  templateUrl: './abandoned-carts.component.html',
  styleUrls: ['./abandoned-carts.component.scss']
})
export class AbandonedCartsComponent implements OnInit {
  public inicio : string;
  public final : string;
  public canAccess : boolean;
  public source : string;
  constructor() { 
    this.inicio = '';
    this.final = '';
    this.canAccess = false;
    this.source = '';
  }

  ngOnInit() {
  }

  public validarCampos(tipo,event){
    console.log(event.target.value);
    if(tipo == 'inicio'){
      this.inicio = event.target.value;
    } else {
      this.final = event.target.value;
    }
    if(this.inicio != '' && this.final != ''){
      this.canAccess = true
      this.source = `https://oldadmin.cuadra.com.mx/procesos/carritos/index.php?S=${this.inicio}&E=${this.final}`;
    } else {
      this.canAccess = false;
    }
  }

}
