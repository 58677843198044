import { Component, OnInit } from '@angular/core';
import { ProductInfoService } from './../../services/product-info.service'
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as XLSX from 'xlsx';
import { promise } from 'protractor';
import { database } from 'firebase';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare var $: any;

@Component({
  selector: 'app-nuevos-modelos',
  templateUrl: './nuevos-modelos.component.html',
  styleUrls: ['./nuevos-modelos.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('1s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
/**
 * FLUJO DE TRABAJO
 * 1. Verificar el archivo del ALTA
 */
export class NuevosModelosComponent implements OnInit {

  public originalFile   : any;
  public fileData       : any;
  public solicitudes    : any;
  public simples        : any;
  public configurables  : any;
  public c2c_color      : any;
  public c2c_tallas     : any;
  public fileIsOK       : boolean;
  public fileIsUpload   : boolean;
  public fileIsProcessed: boolean;
  public isUploading    : boolean;
  public statusFile     : any;
  constructor(
    private productInfoService:ProductInfoService
  ) { 
    this.originalFile    = '';
    this.fileData        = [];
    this.simples         = [];
    this.configurables   = [];
    this.c2c_color       = [];
    this.c2c_tallas      = [];
    this.statusFile      = [];
    this.solicitudes     = [];
    this.fileIsOK        = false;
    this.fileIsProcessed = false;
    this.isUploading     = false;
  }

  ngOnInit() {}

  public clearData(){
    this.originalFile    = '';
    this.fileData        = [];
    this.simples         = [];
    this.configurables   = []; 
    this.c2c_color       = [];
    this.c2c_tallas      = [];
    this.fileIsOK        = false;
    this.fileIsProcessed = false;
    this.fileIsUpload    = false;
    this.isUploading     = false;
    this.statusFile      = [];
    this.getSimples();
    this.getConfigurables('configurable');
    this.getAttributes(193);
    this.getAttributes(183);
    $('#fileInput').val('');
  }

  /**
   * Obtiene todos los EAN registrados en la tienda.
   */
  private getSimples(){
    this.productInfoService.getSimples().subscribe(
      response => {
        this.simples = response.eans;
      },
      error => {
        console.log(error);
      }
    )
  }

  /**
   * Obtiene todos los productos registrados en la tienda.
   */
  private getConfigurables(type_id:string){
    this.productInfoService.getProducts(type_id).subscribe(
      response => {
        this.configurables = response.products;
      },
      error => {
        console.log(error);
      }
    )
  }

  /**
   * @param attributeID Puede ser 183 o 193
   * Verifica que no se intenten crear colores o tallas nuevas
   */
  private getAttributes(attributeID){
    this.productInfoService.getAttributes(attributeID).subscribe(
      response => {
        if(attributeID == 193) this.c2c_color = response.attributes;
        if(attributeID == 183) this.c2c_tallas = response.attributes;
      },
      error => {
        console.log(error);
      }
    )
  }

  /**
   * Verifica si el modelo cuenta con al menos una imagen disponible.
   * @param colour 
   * @param sku 
   */
  private imageNotExists(colour:string, sku:string){
    let answer = this.productInfoService.checkIfImageExists(colour, sku).toPromise();
    return answer;
  }

  /**
   * 
   * @param event 
   */
  public readFile(event){
    $.NotificationApp.send("Cargando...","Estamos verificando el archivo","bottom-right","#000000","info");
    const file: File = event.target.files[0];
    this.originalFile = file;
    this.fileIsOK = true;
    let reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e:any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const aoa = XLSX.utils.sheet_to_json(ws, {header: 1});
      aoa.shift();
      let temp_fileData = [];
      aoa.forEach( async (element:any[]) => {
        console.log('Antes de procesar' + element)
        if(element.length == 0){ // Primer validación: No es una fila vacia.
          this.fileIsOK = false;
          console.log('%c El archivo tiene filas vacias, favor de validarlo e intentar nuevamente ','background: #c83000; color: white');
          $.NotificationApp.send("Error en el archivo","El documento tiene celdas vacias","bottom-right","#946f06","warning");
        }else if(element.length != 13 ){ //2da validación: Todas las columnas necesarias estan presentes.
          this.fileIsOK = false;
          $.NotificationApp.send("Error en el archivo","Uno o más EAN tienen datos incompletos","bottom-right","#946f06","warning");
          console.log('%c La fila del EAN '+ element[0] +' Está incompleta','background: #c83000; color: white'); 
        }else{
          let modelo_color = element[1].toString().toUpperCase() +'_'+ this.removeAccentMark(element[5]).toUpperCase();
          const ean_exist = this.simples.find((e) => { return e.sku == element[0] }); // Verificar que no exista el ean 
          const configurable_exist = this.configurables.find((e) => { return e.sku == (modelo_color) }); // Verificar que el modelo no sea un valor vacio
          const name_is_valid = (element[2] == '' ? false : true); // Verificar que el nombre sea válido
          const description_is_valid = (element[3] == '' ? false : true); // Verificar que la descripcion sea válida
          const short_description_is_valid = (element[4] == '' ? false : true); // verificar que la descripcion corta sea válida
          const color_exist = this.c2c_color.find((e) => { return e.value.toLowerCase() == this.removeAccentMark(element[5]).toLowerCase() }); // verificar que el color ya este registrado
          const size_exist = this.c2c_tallas.find((e) => { return e.value.toLowerCase() == element[6].toString().toLowerCase() }); // verificar que la talla ya esté registrada
          const price_is_valid = (!isNaN(parseFloat(element[7])) && !isNaN(element[7] - 0) ? true : false) // Verificar que el precio sea un valor numérico
          const discount_is_valid = typeof element[8] !== undefined ? true : false  // verificar que el descuento sea un valor numérico o que este vacio
          const brand_is_valid = (element[9] == '' ? false : true); // verificar que la marca no sea un valor vacion
          const gender_is_valid = (element[10] == '' ? false : true); // verificar que el genero no sea un valor vacion
          const category_is_valid = (element[11] == '' ? false : true); // verificar que la categoria no sea un valor vacion
          const subcategory_is_valid = (element[12] == '' ? false : true); // verificar que la subcategoria no sea un valor vacion
          const imageNotExists = false;
          if(ean_exist){ console.log('Ya existe el EAN ' + element[0] + ' En la tienda'); this.fileIsOK = false; }
          if(configurable_exist){ console.log('Ya existe el configurable ' + element[1].toUpperCase() +'_'+ element[5].toUpperCase() + ' En la tienda'); this.fileIsOK = false; }
          if(!name_is_valid){ console.log('El campo del nombre está vacio'); this.fileIsOK = false; }
          if(!description_is_valid){ console.log('El campo de la descripción está vacio'); this.fileIsOK = false; }
          if(!short_description_is_valid){ console.log('El campo de la descripción corta está vacio'); this.fileIsOK = false; }
          if(!color_exist){ console.log('El color ' + element[5] + ' del EAN ' + element[0] + ' No existe'); this.fileIsOK = false; }
          if(!size_exist){ console.log('La talla ' + element[6] + ' del EAN ' + element[0] + ' No existe'); this.fileIsOK = false; }
          if(!price_is_valid){ console.log('El campo del precio no tiene el formato correcto'); this.fileIsOK = false; }
          if(!discount_is_valid){ console.log('El campo del descuento no tiene el formato correcto: '+ element[8] + ' ::'); this.fileIsOK = false; }
          if(!brand_is_valid){ console.log('El campo de la marca está vacio'); this.fileIsOK = false; }
          if(!gender_is_valid){ console.log('El campo del genero está vacio'); this.fileIsOK = false; }
          if(!category_is_valid){ console.log('El campo de la categoria está vacio'); this.fileIsOK = false; }
          if(!subcategory_is_valid){ console.log('El campo de la subcategoría está vacio'); this.fileIsOK = false; }
          if(imageNotExists){ console.log('La imagen principal de este modelo no está disponible'); this.fileIsOK = false; }
          element[1] = modelo_color;
          element[5] = this.removeAccentMark(element[5]);
          console.log(element)
          this.fileData.push(element);
          this.statusFile.push([
            ean_exist ? false : true,
            configurable_exist? false : true,
            name_is_valid,
            description_is_valid,
            short_description_is_valid,
            color_exist ? true : false,
            size_exist ? true : false, 
            price_is_valid,
            discount_is_valid,
            brand_is_valid,
            gender_is_valid,
            category_is_valid,
            subcategory_is_valid,
            true
          ]);
          $.App.init();
        }
      });

    }
  };

  /*This is working */
  public subirArchivo(){
    $.NotificationApp.send("Subiendo...","Estamos subiendo el archivo para procesarlo, un momento por favor","bottom-right","#000000","info");
    const archivo: File = ($("#fileInput").prop('files').length > 0) ? $("#fileInput").prop('files')[0] : '';
    const correo =  $('#emailSolicitante').val();
    let formData:FormData = new FormData();
    formData.append('correo', correo);
    formData.append('archivo', archivo);
    $.ajaxSetup({
      beforeSend: function(xhr) {
        xhr.setRequestHeader('x-api-key', '7cc263a1-a490-4337-8302-151490495e56');
      }
    });
    $.ajax({
      method: "POST",
      url: "https://oldadmin.cuadra.com.mx/reportes/API/uploadFile/index.php",
      contentType:false,
      processData:false,
      dataType: "text",
      data: formData,
      headers: {
        'x-api-key':'7cc263a1-a490-4337-8302-151490495e56'
      },
      success: (r) => {
        let res = JSON.parse(r);
        if(res.error){
          console.warn(r);          
          setTimeout(() => {
            $.NotificationApp.send("Algo salio mal","Algo no salio según lo planeado, favor de intentarlo de nuevo.","bottom-right","#000000","warning");
          }, 1500);
        }else{
          this.fileIsUpload = true;
          setTimeout(() => {
            $.NotificationApp.send("Vamos muy bien","La información esta lista para procesarse","bottom-right","#15925f","success");
          }, 1500);
        }
      },
      error: (r) => {
        setTimeout(() => {
          $.NotificationApp.send("Hubo un error","Algo no salio según lo planeado, favor de intentarlo de nuevo.","bottom-right","#000000","error");
        }, 1500);
        console.error(r);
      }
    });	
  }

  public Comparator(a, b) {
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;
    return 0;
  }

  public createProducts(){
    this.isUploading = true;
    var sortedArray = this.fileData.sort(this.Comparator);
    let porModelo:any = [];
    let finalizado = 0;
    sortedArray.forEach(  element => {
      if(porModelo[element[1]]){
        porModelo[element[1]].push(element)
      } else {
        porModelo[element[1]] = [element]
      }
    });
    
    for(var modelo in porModelo) {
      this.productInfoService.createProducts(porModelo[modelo]).subscribe(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
        }
      )
    }
    $.NotificationApp.send("Productos activos","La información se cargo correctamente. Es necesario esperar unos minutos para ver estos productos registrados en Magento","bottom-right","#15925f","success");
    this.clearData()
  }

  public getSolicitudes(){
    this.productInfoService.getSolicitudes('alta-productos').subscribe(
      response => {
        console.log(response);
        this.solicitudes = response.msj;
      },
      error => {
        console.log(error);
      }
    )
  }

  private removeAccentMark(originalString:string){
    let normalizedString = originalString.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return normalizedString;
  } 
}


