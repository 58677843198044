import { Component, OnInit } from '@angular/core';
import { OrdersInfoService } from './../services/orders-info.service'
import { AngularFireAuth } from '@angular/fire/auth';
import * as XLSX from 'xlsx';
declare var $ : any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public ordersByStatus             : any;
  public orden                      : string;
  public status                     : any;
  public indice                     : number;
  public nuevoStatusSeleccionado    : boolean;
  public usuario                    : string;
  public canChangeStatus            : boolean;
  constructor(
    private ordersInfoService:OrdersInfoService,
    private afAuth: AngularFireAuth,
  ) {
    this.ordersByStatus = [];
    this.status = [];
    this.nuevoStatusSeleccionado = false;
    this.indice = 999;
    this.usuario = '';
  }

  ngOnInit() {
    this.getOrdersByStatus();
    this.getCurrentUser()
    console.log(this.status.length)
  }

  public getOrdersByStatus(){
    this.ordersInfoService.getOrdersByStatus().subscribe(
      response => {
        console.log(response)
        this.ordersByStatus = response.estatus
      },
      error => {
        console.warn(error);
      }
    );
  }

  public getStatus(){
    this.status = [];
    this.indice = 999;
    this.ordersInfoService.status(this.orden).subscribe(
      response => {
        this.status = response.status;
        $.NotificationApp.send("Exito",response.msj,"bottom-right","#15925f","info");
        console.log(this.status);
      }, 
      error => {
        $.NotificationApp.send("Error","Algo salio mal. Por favor, intentalo de nuevo.","bottom-right","#946f06","error");
        console.warn(error);
      }
    )
  }

  public updateStatus(){
    this.ordersInfoService.updateStatus(this.orden,this.status[this.indice][0],this.status[this.indice][1]).subscribe(
      response => {
        if(!response.error) {
          $.NotificationApp.send("Proceso finalizado",response.mensaje,"bottom-right","#15925f","success");
        } else {
          $.NotificationApp.send("Error","Algo salio mal. Por favor, intentalo de nuevo.","bottom-right","#946f06","warning");
          console.log(response);
        }
      }, 
      error => {
        $.NotificationApp.send("Error","Algo salio mal. Por favor, intentalo de nuevo.","bottom-right","#946f06","error");
        console.warn(error);
      }
    )
  }

  public getCurrentUser(){
    this.usuario = this.afAuth.auth.currentUser.email;
    this.canChangeStatus = true;
  }
}
