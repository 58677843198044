export class App {
    protected url: string;
    protected headers: any;

    constructor(){
        this.headers = {
            'x-api-key': '7cc263a1-a490-4337-8302-151490495e56',
            'Content-Type': 'application/json;charset=UTF-8'
        };
        this.url = 'https://oldadmin.cuadra.com.mx/reportes/API/'
    }
}
