import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as XLSX from 'xlsx';
//services
import { ProductInfoService } from './../services/product-info.service';
declare var $: any;

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('1s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('1s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class CategoriasComponent implements OnInit {
  public fileData          :any;     // información del CSV
  public InfoStatus        :any;     // información sobre el estatus de la información (es válida o no)
  public originalFile      :any;     // archivo original
  public configurables     :any;     // configurables existentes
  public email             :string;  // email del usuario
  public message           :string;  // mensaje a mostrar al usuario
  public dataIsOk          :boolean; // sí la información es correcta, se solicita correo electrónico
  public emailIsOk         :boolean; // si el correo es válido, se registra la solicitud
  public isUploading       :boolean; // si es positivo, significa que se está subiendo el archivo
  public canUploadFile     :boolean; // Si es verdadero, indica que es posible realizar la carga del documento
  public Uploaded          :boolean; // si es positivo, significa que ya esta arriba
  public isReading         :boolean; // si esta leyendo el documento, es verdadero
  constructor(
    private productInfoService :ProductInfoService,
  ) { 
    this.fileData         = [];
    this.InfoStatus       = [];
    this.configurables    = [];
    this.email            = '';
    this.message          = '';
    this.dataIsOk         = false;
    this.emailIsOk        = false;
    this.isUploading      = false;
    this.Uploaded         = false;
    this.canUploadFile    = false;
    this.isReading        = false;
  }

  ngOnInit(): void{
    this.getProductos('configurable');
  }

  ngAfterViewInit(): void {}

  /**
   * Returns all variables to their original value
   */
  public cleanData(){
    console.log('%c Cleaning data... ','background:#0acf97; color: white')
    this.fileData         = [];
    this.InfoStatus       = [];
    this.email            = '';
    this.message          = '';
    this.dataIsOk         = false;
    this.emailIsOk        = false;
    this.isUploading      = false;
    this.Uploaded         = false;
    this.canUploadFile    = false;
    this.isReading        = false;
    this.configurables  = [];
    this.getProductos('configurable');
    $('#fileInput').val('');
  }

  /**
   * Obtiene todos los productos registrados en la tienda.
   */
  private getProductos(type_id:string){
    this.productInfoService.getProducts(type_id).subscribe(
      response => {
        this.configurables = response.products;
      },
      error => {
        console.log(error);
      }
    )
  }

  /**
   * Read a CSV file and evaluate the data. If there are any error, displays a message to the user
   * @param event 
   */
  public readFile(event){
    const file: File = event.target.files[0];
    this.originalFile = file;
    let reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    this.dataIsOk = true;
    this.isReading = true;
    reader.onload = (e:any) => {
      // Read workbook
      const binaryString: string = e.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(binaryString, {type: 'binary'});
      // grab first sheet
      const workSheetName: string = workbook.SheetNames[0];
      const workSheet: XLSX.WorkSheet = workbook.Sheets[workSheetName];
      // convert data into string
      const arrayOfArrays:any[] = XLSX.utils.sheet_to_json(workSheet, {header: 1});
      // Drop first row
      arrayOfArrays.shift();
      for(let i = 0; i < arrayOfArrays.length; i++) {
        if(arrayOfArrays[i].length == 0) {
          console.warn('There is empty rows in the file');
          this.dataIsOk = false;
          this.message = 'El archivo tiene filas vacias, favor de validarlo e intentar nuevamente';
          $.NotificationApp.send("Error en el archivo",this.message,"bottom-right","#946f06","warning");
          break; 
        } else if( arrayOfArrays[i].length != 2){
          console.warn('Some rows doesn´t have correct data');
          this.dataIsOk = false;
          this.message = 'El archivo tiene un número incorrecto de columnas. favor de validarlo e intentar nuevamente';
          $.NotificationApp.send("Error en el archivo",this.message,"bottom-right","#946f06","warning");
          break; 
        } else {
          const configurable_exist = this.configurables.find((e) => e.sku == arrayOfArrays[i][0].trim()); // Verificar que el modelo no sea un valor vacio
          const hasPosition = !isNaN(parseFloat(arrayOfArrays[i][1]));
          this.dataIsOk = ( !!configurable_exist && hasPosition);
          this.InfoStatus.push( [ !!configurable_exist, hasPosition ] );
          this.fileData.push( [ arrayOfArrays[i][0].trim(), arrayOfArrays[i][1]] ); 
        }
      }
      this.isReading = false;
      $(document).ready(function(){
        $.App.init();
      });
    }
  }

  public cargarInformacion(){
    this.isUploading = true;
    $.NotificationApp.send("Cargando...","Estamos subiendo la información, espera un poco","bottom-right","#000000","info");
    this.productInfoService.sortProducts(this.fileData).subscribe(
      response => {
        if(!response.error){
          $.NotificationApp.send("Generando respaldos","Esto tomara solo un minuto, sigue con nosotros","bottom-right","#15925f","success",10000);
          setTimeout(() => {
            this.backupFile();  
          }, 1500);
        }else{
          $.NotificationApp.send("Error","Algo salio mal. Favor de intentarlo nuevamente","bottom-right","#FF0000","error");
          this.cleanData();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error","Algo salio mal. Favor de intentarlo nuevamente","bottom-right","#FF0000","error");
        this.cleanData();
      }
    );
  }

  private backupFile(){
    const archivo = this.originalFile;
    const correo =  this.email;
    let formData:FormData = new FormData();
    formData.append('correo', correo);
    formData.append('archivo', archivo);
    formData.append('tipo_solicitud','posiciones');
    formData.append('nombre_archivo','posiciones-productos');
    $.ajaxSetup({
      beforeSend: function(xhr) {
        xhr.setRequestHeader('x-api-key', '7cc263a1-a490-4337-8302-151490495e56');
      }
    });
    $.ajax({
      method: "POST",
      url: "https://oldadmin.cuadra.com.mx/reportes/API/uploadFile/index-manager.php",
      contentType:false,
      processData:false,
      dataType: "text",
      data: formData,
      headers: {
        'x-api-key':'7cc263a1-a490-4337-8302-151490495e56'
      },
      success: (response) => {
        let res = JSON.parse(response);
        if(res.error){
          $.NotificationApp.send("Error","Ocurrio un error durante el respaldo, pero la información fue cargada.","bottom-right","#FF0000","error");
        }else{
          setTimeout(() => {
            $.NotificationApp.send("Proceso finalizado","Se ha registrado la información en Magento","bottom-right","#15925f","success");
          }, 1500);
          this.cleanData()
          this.Uploaded = true;
        }
      },
      error: (response) => {
          console.warn(response);
          $.NotificationApp.send("Error","Algo salio mal","bottom-right","#FF0000","error");
      }
    });	
  }

  /**
   * Checks if obj is an empty object. It is return true
   */
  private isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key) || !isNaN(obj))
            return false;
    }
    if(!isNaN(obj)){
      return false
    }
    return true;
  }

  /**
   * Regex for email sintax
   * Every email need to has:
   *  >user
   *  >@ 
   *  >domaing with at least one dot
   */
  public emailIsSetted(){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.canUploadFile = re.test(this.email);
  }
}
