import { BrowserModule } from '@angular/platform-browser';
import { NgModule, forwardRef } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { Error404Component } from './error404/error404.component';
import { TopbarComponent } from './topbar/topbar.component';
import { LeftsideMenuComponent } from './leftside-menu/leftside-menu.component';
import { ConfirmacionMailComponent } from './confirmacion-mail/confirmacion-mail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Firebase modules
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthGuardModule, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { NuevosModelosComponent } from './pages/nuevos-modelos/nuevos-modelos.component';
import { CargarGuiasComponent } from './pages/cargar-guias/cargar-guias.component';
import { ActualizarGaleriaComponent } from './pages/actualizar-galeria/actualizar-galeria.component';
import { AdministrarOfertasComponent } from './pages/administrar-ofertas/administrar-ofertas.component';
import { AdministrarNovedadesComponent } from './pages/administrar-novedades/administrar-novedades.component';
import { ActualizarEansComponent } from './pages/actualizar-eans/actualizar-eans.component';
import { AdministrarComplementosComponent } from './pages/administrar-complementos/administrar-complementos.component';
import { DhlComponent } from './pages/dhl/dhl.component';
import { AbandonedCartsComponent } from './abandoned-carts/abandoned-carts.component';
import { OrdenesEstatusComponent } from './ordenes-estatus/ordenes-estatus.component';
import { CategoriasComponent } from './categorias/categorias.component';
import { AdministrarUpsellComponent } from './pages/administrar-upsell/administrar-upsell.component';

//Firebase config
const config = {
  apiKey: "AIzaSyCEG_jP7HLzEZTACb7JsyBCT9Nf3mj7hEQ",
  authDomain: "cuadra-4baee.firebaseapp.com",
  databaseURL: "https://cuadra-4baee.firebaseio.com",
  projectId: "cuadra-4baee",
  storageBucket: "cuadra-4baee.appspot.com",
  messagingSenderId: "103455386107",
  appId: "1:103455386107:web:ef9507621a4f9400dbc35e"
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    HomeComponent,
    Error404Component,
    TopbarComponent,
    LeftsideMenuComponent,
    ConfirmacionMailComponent,
    NuevosModelosComponent,
    CargarGuiasComponent,
    ActualizarGaleriaComponent,
    AdministrarOfertasComponent,
    AdministrarNovedadesComponent,
    ActualizarEansComponent,
    AdministrarComplementosComponent,
    DhlComponent,
    AbandonedCartsComponent,
    OrdenesEstatusComponent,
    CategoriasComponent,
    AdministrarUpsellComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(config),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthGuardModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
