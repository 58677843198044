import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { App } from './app'
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class ProductInfoService extends App{

  constructor(private http:HttpClient) {
    super();
  }

  public getSimples():Observable<any>{
    const params:string = JSON.stringify({
      action: 'getEans'
    });
    return this.http.post(this.url+`get-eans/`, params, { headers: this.headers });
  }

  public getProducts(type_id):Observable<any>{
    const params:string = JSON.stringify({
      type_id
    });
    return this.http.post(this.url+`get-products/`, params, { headers: this.headers });
  }

  public setNews(data):Observable<any>{
    console.log(data);
    const params:string = JSON.stringify({
      data
    });
    return this.http.post(this.url+`setNews/`, params, { headers: this.headers });
  }

  public sortProducts(data):Observable<any>{
    const params:string = JSON.stringify({
      data
    });
    return this.http.post(this.url+`posiciones/`, params, { headers: this.headers });
  }

  public setRelatedProducts(data):Observable<any>{
    console.log(data);
    const params:string = JSON.stringify({
      data
    });
    return this.http.post(this.url+`relation/set.php`, params, { headers: this.headers });
  }

  public setUpsellProducts(data):Observable<any>{
    console.log(data);
    const params:string = JSON.stringify({
      data
    });
    return this.http.post(this.url+`relation/upsell.php`, params, { headers: this.headers });
  }

  public getAttributes(attributeID):Observable<any>{
    const params:string = JSON.stringify({
      attribute_id: attributeID
    });
    return this.http.post(this.url+`get-attributes/`, params, { headers: this.headers });
  }

  public getSolicitudes(tipo:string):Observable<any>{
    const params:string = JSON.stringify({
      tipo: tipo
    });
    return this.http.post(this.url+`solicitudes/productos-nuevos/`, params, { headers: this.headers });
  }

  public checkIfImageExists(colour:string, sku:string):Observable<any>{
    const params:string = JSON.stringify({
      color: colour,
      modelo: sku
    });
    return this.http.post(this.url+'images/',params, {headers: this.headers});
  }

  public verifyGallery(sku:string):Observable<any>{
    const params:string = JSON.stringify({
      modelo: sku
    });
    return this.http.post(this.url+'images/validar_galeria.php',params, {headers: this.headers});
  }

  public updateGallery(sku:string,galeria:any,):Observable<any>{
    console.log(galeria);
    const params:string = JSON.stringify({
      modelo:   sku,
      galeria:  galeria,
    });
    return this.http.post(this.url+'images/update_images.php',params, {headers: this.headers});
  }

  public createProducts(fileData:any){
    console.log(fileData)
    const params:string = JSON.stringify({
      fileData
    });
    return this.http.post(this.url+'new-products/index.php',params, {headers: this.headers});
  }


}
