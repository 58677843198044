import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-actualizar-eans',
  templateUrl: './actualizar-eans.component.html',
  styleUrls: ['./actualizar-eans.component.scss']
})
export class ActualizarEansComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
